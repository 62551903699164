import React from 'react';
import { Row, Spin, Col } from 'antd';
import './spin.css';

export const Spinner = ({textLoad}) => {
    return (
        <Row justify="center" align="middle" style={{marginTop:'20em'}}>
          <Col>
              <Spin style={{width:'4rem', height:'4rem'}}/> 
          </Col>
          <Col>
            { textLoad }
          </Col>
        </Row>
     )
  }

export const SpinnerLoad = ({textLoad}) => {
    return (
        <Row>
          <Col>
              <Spin style={{width:'4rem', height:'4rem'}}/> 
          </Col>
          <Col>
            { textLoad }
          </Col>
        </Row>
     )
  }