const initialState = {
  sidebarShow: false,
  isLoading: false,
  isLoadingButton: false,

  // handle Modal 
  isModalConfirm: false,
  isModalForm: false,
  
  otoritas_id: 'null',
  readyAssign: false,
  scheduleTap: {
    uid:''
  },

  //notif toas
  notifToas: false,
  dataToas: {
    title: '',
    body: ''
  },

  isEdit: false,
  dataEdit: {},
  dataDelete: {},

}

const changeState = (state = initialState, { type, val, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
     case 'LOADING':
       return {
         ...state,
         isLoading: val
       }

    default:
      return state
  }


// const changeState = (state = initialState, { type, val, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//      case 'LOADING':
//        return {
//          ...state,
//          isLoading: val
//        }
//      case 'Loading_Button':
//        return {
//           ...state,
//           isLoadingButton: val
//        }
//      case 'SET_MODAL_CONFIRM':
//        return {
//          ...state,
//          isModalConfirm: val
//        }

//      case 'SET_MODAL_FORM':
//        return {
//          ...state,
//          isModalForm: val
//        }

//      case 'IS_EDIT':
//        return {
//          ...state,
//          isEdit: val
//        }

//      case 'DATA_EDIT':
//        return {
//          ...state,
//          dataEdit: val
//        }

//      case 'DATA_DELETE':
//        return {
//          ...state,
//          dataDelete: val
//        }

//       case 'CHANGE_OTORITAS':
//         return {
//           ...state,
//           otoritas_id: val
//         }

//       // case notification
//       case 'TOAS_NOTIF':
//         return {
//           ...state,
//           notifToas: val
//         }
//       case 'DATA_TOAS':
//         return {
//           ...state,
//           dataToas: val
//         }
      
//     default:
//       return state
//   }
}

export default changeState
