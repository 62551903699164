import './App.css';
import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import {Spinner} from './component/molecules/spinner';
// import './scss/style.scss';

// containers 
const TheLayout = React.lazy(() => import('./containers/templates/light-mode/layout'));

//layout landing page
// const UserLayout = React.lazy(() => import('./containers/templates/user-layout/layout'));
const Home = React.lazy(() => import('./containers/page/user-page/home'));
const AllApps = React.lazy(() => import('./containers/page/user-page/all-apps'));
const OverView = React.lazy(() => import('./containers/page/user-page/overview'));
const Feature = React.lazy(() => import('./containers/page/user-page/features'));
const LoginUser = React.lazy(() => import('./containers/page/user-page/login-user'));

// layout guest / admin 
const Profile = React.lazy(() => import('./containers/page/admin-guest-login/profile'));
const OrderApp = React.lazy(() => import('./containers/page/admin-guest-login/order'));
const MySubscription = React.lazy(() => import('./containers/page/admin-guest-login/subscribtion'));


// pages 
const Login = React.lazy(() => import('./containers/page/login'))

class App extends Component {

  render() {
    return (
      <>
        <HashRouter>
          <Suspense fallback={<Spinner />}>
            <Switch>
             {/* <Route exact path="/userlayout" name="UserLayout" render={props => <UserLayout {...props}/>} /> */}
             <Route exact path="/login" name="Login" render={props => <Login {...props}/>} />
             <Route exact path="/" name="Homeadmin" render={props => <TheLayout {...props}/>} />

              {/* Layout Profile Guest Admin  */}
              <Route exact path="/user/profile/" name="Profile" render={props => <Profile {...props}/>} />
              <Route exact path="/user/order-apps/" name="MySubscription" render={props => <OrderApp {...props}/>} />
              <Route exact path="/user/my-subscription/" name="MySubscription" render={props => <MySubscription {...props}/>} />

              {/* layout landing page */}
             <Route exact path="/home" name="Home" render={props => <Home {...props}/>} />
             <Route exact path="/all-apps/" name="AllApps" render={props => <AllApps {...props}/>} />
             <Route exact path="/all-apps/:type" name="AllApps" render={props => <AllApps {...props}/>} />
             <Route exact path="/overview/:idproduct" name="OverView" render={props => <OverView {...props}/>} />
             <Route exact path="/feature/:idproduct" name="Feature" render={props => <Feature {...props}/>} />
             <Route exact path="/user/:typeuser" name="LoginUser" render={props => <LoginUser {...props}/>} />
          
            </Switch>
          </Suspense>
        </HashRouter>
      </>
    )
  }
}

export default App;
